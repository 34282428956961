import React from "react"
import { graphql, Link } from "gatsby"
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  getProjectUrl,
} from "../lib/helpers"
import Seo from "../components/seo"
import { Figure } from "../components/MainImage"
import EmailUs from "../components/EmailUs"
export const query = graphql`
  query {
    posts: allSanityProject(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          image {
            asset {
              url
              _id
            }
          }
          title
          excerpt
          slug {
            current
          }
        }
      }
    }
  }
`
const ProjectPage = props => {
  const { data } = props
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts).filter(filterOutDocsWithoutSlugs)
    : []
  return (
    <>
      <Seo title="Projects" />
      <div className=" bg-[#F9FAFB] min-h-screen py-24">
        <div className="w-10/12 mx-auto">
          <div className="my-6 mb-56 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {postNodes &&
              postNodes.map(node => {
                return (
                  <div key={node.id} className="rounded-[20px]">
                    <Link to={getProjectUrl(node.slug.current)}>
                      <Figure
                        node={node.image}
                        alt={node.title}
                        className=" rounded-[20px] aspect-[208/125] "
                      />
                    </Link>
                    <div className="p-2">
                      {node.title && (
                        <Link to={getProjectUrl(node.slug.current)}>
                          <h4 className="text-xl mt-4 mb-2 hover:underline">
                            {node.title}
                          </h4>
                        </Link>
                      )}
                      {node.excerpt && (
                        <p className="text-[#A0A3BD]">{node.excerpt}</p>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      <EmailUs />
    </>
  )
}

export default ProjectPage
